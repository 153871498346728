





























import { Vue, Component, Prop } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { EmitEvents, ServerSideEventsPath } from '@/types/events'

import { ScoreChartDataInterface } from '@/types/scoreChart'

import { ThemeOptions } from '@/types/ui'

import { CurrencyOptions } from '@/types/currency'

let am4core: any = null

let am4charts: any = null

let am4themesAnimated: any = null

let am4themesDark: any = null

am4core = require('@amcharts/amcharts4/core')
am4charts = require('@amcharts/amcharts4/charts')
am4themesAnimated = require('@amcharts/amcharts4/themes/animated')
am4themesDark = require('@amcharts/amcharts4/themes/dark')

@Component({
  name: 'ScoreChart',
  components: {
    ChartHeader: () => import('@/components/ChartHeader.vue'),
  },
  computed: {
    ...mapState({
      loading: (state: any) => state.loading,
    }),
  },
})
export default class ScoreChart extends Vue {
  @Prop({ default: '180px' }) readonly chartHeight!: string
  @Prop({ default: '100%' }) readonly chartWidth!: string
  @Prop({ default: 'BTC' }) readonly coinName!: string
  @Prop({ default: 'dark' }) readonly theme!: ThemeOptions
  @Prop({ default: '#212121' }) readonly bgColor!: string
  @Prop({ default: 'USD' }) readonly currencyCode!: CurrencyOptions

  eventStreamUrl = process.env.VUE_APP_SSE_HOST_URL
  priceEventStream!: EventSource
  ptcChangeEventStream!: EventSource
  indicatorStream!: EventSource

  loading!: boolean

  scoreChartData: ScoreChartDataInterface | any = {}

  async fetchCoinDetails() {
    try {
      const data = await this.$store.dispatch('FETCH_COIN_DETAILS', {
        coinName: this.coinName,
        currencyCode: this.currencyCode,
      })

      return data
    } catch (error) {
      throw new Error(error)
    }
  }

  $refs!: { chartdiv: HTMLElement }

  chart: any = null
  hand: any = null
  label: any = null
  label2: any = null

  get technicalScore() {
    return this.scoreChartData.technical_score
  }

  data = {
    gradingData: [
      {
        title: 'Very Bearish',
        color: '#f10000',
        lowScore: 0,
        highScore: 20,
      },
      {
        title: 'Slightly Bearish',
        color: '#f08c00',
        lowScore: 20,
        highScore: 40,
      },

      {
        title: 'Neutral',
        color: '#FFCA28',
        lowScore: 40,
        highScore: 60,
      },

      {
        title: 'Slightly Bullish',
        color: '#40aa00',
        lowScore: 60,
        highScore: 80,
      },

      {
        title: 'Very Bullish',
        color: '#307820',
        lowScore: 80,
        highScore: 100,
      },
    ],
  }

  async mounted() {
    this.$gtag.event(`Open Widget | ${window.location.href}`, {
      event_category: `Trend Widget`,
      event_label: 'render',
      value: 1,
    })

    try {
      this.scoreChartData = await this.fetchCoinDetails()
      this.renderChart()
    } catch (error) {
      this.scoreChartData = {}
    }

    /** Start Price Event Stream **/
    this.priceEventStream = new EventSource(
      this.eventStreamUrl +
        ServerSideEventsPath.priceBroadcast +
        '?qc_key=' +
        this.coinName
    )

    /** Start Percent Change Event Stream **/
    this.ptcChangeEventStream = new EventSource(
      this.eventStreamUrl +
        ServerSideEventsPath.ptcChangeBroadcast +
        '?qc_key=' +
        this.coinName
    )

    /** Start Technical Score Change Event Stream **/
    this.indicatorStream = new EventSource(
      this.eventStreamUrl +
        ServerSideEventsPath.indicatorBroadcast +
        '?indicator=TREND&trend_values=technical_score'
    )

    /** Price Change Indicator Stream Listener **/
    if (this.priceEventStream) {
      this.priceEventStream.addEventListener('message', (e: any) => {
        this.$root.$emit(EmitEvents.priceStream, JSON.parse(e.data))
      })
    }

    /** Percent Change Indicator Stream Listener **/
    if (this.ptcChangeEventStream) {
      this.ptcChangeEventStream.addEventListener('message', (e: any) => {
        this.$root.$emit(EmitEvents.ptcChangeStream, JSON.parse(e.data))
      })
    }

    /** Technical Score  Indicator Stream Listener **/
    if (this.indicatorStream) {
      this.indicatorStream.addEventListener('message', (e: any) => {
        this.$root.$emit(EmitEvents.technicalScoreStream, JSON.parse(e.data))
      })
    }

    this.$root.$on(
      EmitEvents.technicalScoreStream,
      ({ value }: { value: any }) => {
        if (value.key === 'technical_score') {
          if (value.qc_key === this.coinName) {
            this.updateScore(value.value)
          }
        }
      }
    )
  }

  /** Grading Lookup */
  lookUpGrade(lookupScore: any, grades: any) {
    for (let i = 0; i < grades.length; i++) {
      if (
        grades[i].lowScore <= lookupScore &&
        grades[i].highScore >= lookupScore
      ) {
        return grades[i]
      }
    }
    return null
  }

  renderChart() {
    if (this.theme === 'dark') {
      am4core.useTheme(am4themesDark.default)
    } else {
      am4core.unuseTheme(am4themesDark.default)
      am4core.useTheme(am4themesAnimated.default)
    }

    am4core.options.autoDispose = true

    am4core.options.autoSetClassName = true
    am4core.addLicense('CH187387301')
    const chartMin = 0
    const chartMax = 100

    this.chart = am4core.create(this.$refs.chartdiv, am4charts.GaugeChart)
    this.chart.hiddenState.properties.opacity = 0
    this.chart.fontSize = 11
    this.chart.innerRadius = am4core.percent(90)
    this.chart.resizable = true
    this.chart.padding(0, 0, 0, 0)

    /**
     * Normal axis
     */

    const axis = this.chart.xAxes.push(new am4charts.ValueAxis())
    axis.min = chartMin
    axis.max = chartMax
    axis.strictMinMax = true
    axis.renderer.radius = am4core.percent(90)
    axis.renderer.inside = true
    axis.renderer.line.strokeOpacity = 0.1
    axis.renderer.ticks.template.disabled = false
    axis.renderer.ticks.template.strokeOpacity = 1
    axis.renderer.ticks.template.strokeWidth = 0.5
    axis.renderer.ticks.template.length = 5
    axis.renderer.grid.template.disabled = true
    axis.renderer.labels.template.radius = am4core.percent(15)
    axis.renderer.labels.template.fontSize = '0.9em'

    /**
     * Axis for ranges
     */

    const axis2 = this.chart.xAxes.push(new am4charts.ValueAxis())
    axis2.min = chartMin
    axis2.max = chartMax
    axis2.strictMinMax = true
    axis2.renderer.labels.template.disabled = true
    axis2.renderer.ticks.template.disabled = true
    axis2.renderer.grid.template.disabled = false
    axis2.renderer.grid.template.opacity = 0.5
    axis2.renderer.labels.template.bent = true
    axis2.renderer.labels.template.fill = am4core.color(
      this.theme === 'light' ? '#000' : '#fff'
    )
    axis2.renderer.labels.template.fontWeight = 'bold'
    axis2.renderer.labels.template.fillOpacity = 0.3

    /**
     Ranges
     */

    for (const grading of this.data.gradingData) {
      const range = axis2.axisRanges.create()
      range.axisFill.fill = am4core.color(grading.color)
      range.axisFill.fillOpacity = 0.8
      range.axisFill.zIndex = -1
      range.value = grading.lowScore > chartMin ? grading.lowScore : chartMin
      range.endValue =
        grading.highScore < chartMax ? grading.highScore : chartMax
      range.grid.strokeOpacity = 0
      range.stroke = am4core.color(grading.color).lighten(-0.1)
      range.label.inside = true
      range.label.text = grading.title.toUpperCase()
      range.label.inside = true
      range.label.location = 0.5
      range.label.inside = true
      range.label.radius = am4core.percent(-8)
      range.label.paddingBottom = -5
      range.label.fontSize = '.9em'
    }

    const matchingGrade = this.lookUpGrade(
      this.technicalScore,
      this.data.gradingData
    )

    /**
     * Label 1
     */

    this.label = this.chart.radarContainer.createChild(am4core.Label)
    this.label.isMeasured = false
    this.label.fontSize = '3.2em'
    this.label.x = am4core.percent(50)
    this.label.paddingBottom = 15
    this.label.horizontalCenter = 'middle'
    this.label.verticalCenter = 'bottom'
    this.label.text = this.technicalScore.toFixed(1)
    this.label.fill = am4core.color(matchingGrade.color)

    /**
     * Label 2
     */

    this.label2 = this.chart.radarContainer.createChild(am4core.Label)
    this.label2.isMeasured = false
    this.label2.fontSize = '1.7em'
    this.label2.horizontalCenter = 'middle'
    this.label2.verticalCenter = 'bottom'
    this.label2.text = matchingGrade.title.toUpperCase()
    this.label2.fill = am4core.color(matchingGrade.color)

    /**
     * Label 3
     */

    const label3 = this.chart.radarContainer.createChild(am4core.Label)
    label3.isMeasured = false
    label3.fontSize = '.95em'
    label3.horizontalCenter = 'middle'
    label3.verticalCenter = 'bottom'
    label3.text = 'Quantify Crypto'.toUpperCase()
    label3.fontWeight = 500
    label3.fill = am4core.color(matchingGrade.color)
    label3.paddingBottom = -10
    label3.url = 'https://quantifycrypto.com'
    label3.urlTarget = '_blank'

    let hoverState = label3.states.create('hover')
    hoverState.properties.fill = am4core.color('#536af6')
    hoverState.properties.fillOpacity = 0.8

    /**
     * Hand
     */

    this.hand = this.chart.hands.push(new am4charts.ClockHand())
    this.hand.axis = axis2
    this.hand.innerRadius = am4core.percent(50)
    this.hand.startWidth = 7
    this.hand.pin.disabled = true
    this.hand.value = this.technicalScore
    this.hand.fill = am4core.color(this.theme === 'light' ? '#444' : '#888')
    this.hand.stroke = am4core.color('#000')
    const value = this.technicalScore
    this.hand.showValue(value, am4core.ease.cubicOut)
  }

  updateScore(score: number): void {
    const matchingGrade = this.lookUpGrade(score, this.data.gradingData)
    this.label.text = score.toFixed(1)
    this.label.fill = matchingGrade.color
    this.label2.text = matchingGrade.title.toUpperCase()
    this.label2.fill = matchingGrade.color
    this.hand.showValue(score, 1000, am4core.ease.cubicOut)
  }

  beforeDestroy() {
    if (this.priceEventStream) {
      this.priceEventStream.close()
    }

    if (this.ptcChangeEventStream) {
      this.ptcChangeEventStream.close()
    }

    if (this.indicatorStream) {
      this.indicatorStream.close()
    }

    if (this.chart) {
      this.chart.dispose()
    }
  }
}
