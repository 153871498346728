export enum EmitEvents {
  priceStream = 'price-stream',
  ptcChangeStream = 'ptc-change-stream',
  technicalScoreStream = 'technical-score-stream',
}

export enum ServerSideEventsPath {
  priceBroadcast = '/price-broadcast',
  ptcChangeBroadcast = '/ptc-change-broadcast',
  indicatorBroadcast = '/indicator-broadcast',
}
