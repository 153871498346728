import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueGtag from 'vue-gtag'
import store from './store'
import vueCustomElement from 'vue-custom-element'

Vue.use(vueCustomElement)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
Vue.use(VueAxios, axios)
Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GTAG_ID },
})

Vue.use(vueCustomElement)
Vue.customElement('qc-trend', new App().$options, {
  beforeCreateVueInstance(RootComponentDefinition) {
    RootComponentDefinition.store = store
    return RootComponentDefinition
  },
})
