














import { Component, Vue, Prop } from 'vue-property-decorator'
import ScoreChart from '@/components/ScoreChart.vue'
import ChartHeader from '@/components/ChartHeader.vue'
import { CurrencyOptions } from '@/types/currency'
import { ThemeOptions } from '@/types/ui'

@Component({
  components: {
    ScoreChart,
    ChartHeader,
  },
})
export default class App extends Vue {
  @Prop({ default: '180px' }) readonly height!: string
  @Prop({ default: '100%' }) readonly width!: string
  @Prop({ default: 'BTC' }) readonly coinName!: string
  @Prop({ default: '#1E1E1E' }) readonly background!: string
  @Prop({ default: 'dark' }) readonly theme!: ThemeOptions
  @Prop({ default: 'USD' }) readonly currencyCode!: CurrencyOptions
}
