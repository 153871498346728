































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
import { EmitEvents } from '@/types/events'
import { ThemeOptions } from '@/types/ui'
import { CurrencyInterface } from '@/types/currency'

@Component({
  name: 'ChartHeader',
  computed: {
    ...mapState({
      loading: (state: any) => state.loading,
    }),
  },
})
export default class ChartHeader extends Vue {
  @Prop({ default: '100%' }) readonly chartWidth!: string
  @Prop({ default: 'dark' }) readonly theme!: ThemeOptions
  @Prop({ default: '#212121' }) readonly bgColor!: string
  @Prop({ default: null }) readonly qcKey!: string
  @Prop({ default: '' }) readonly symbol!: string
  @Prop({ default: null }) readonly priceUsd!: number
  @Prop({ default: null }) readonly price24h!: number

  isPositive = false
  priceUsdLocal = this.priceUsd
  price24hLocal = this.price24h
  coinName = this.qcKey

  currency: CurrencyInterface = {
    locale: 'en',
    conv_rate: 1,
    currency_code: 'USD',
  }

  @Watch('priceUsd')
  onPriceUsdChange(value: number) {
    this.priceUsdLocal = value
  }

  @Watch('price24h')
  onPtcChange(value: number) {
    this.price24hLocal = value
  }

  mounted() {
    this.priceUsdLocal = this.priceUsd
    this.price24hLocal = this.price24h

    this.currency = this.$store.state.currency

    /** Event listener  for price stream */
    this.$root.$on(EmitEvents.priceStream, (price: any) => {
      this.updatePriceUsd(price)
    })

    /** Event listener  for ptc change */
    this.$root.$on(EmitEvents.ptcChangeStream, (price: any) => {
      if (price.change_usd_24h) {
        this.updatePrice24h(price)
      }
    })
  }

  updatePriceUsd(data: any) {
    if (data.qc_key === this.qcKey) {
      data.price_usd > this.priceUsdLocal
        ? (this.isPositive = true)
        : (this.isPositive = false)
      this.priceUsdLocal = data.price_usd * this.currency.conv_rate
    }
  }

  updatePrice24h(data: any) {
    if (data.qc_key === this.qcKey && data.change_usd_24h) {
      this.price24hLocal = data.change_usd_24h
    }
  }
}
