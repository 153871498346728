import Vue from 'vue'
import Vuex from 'vuex'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    coinName: '',
    currency: { currency_code: '', locale: '', conv_rate: 1 },
    loading: true,
    scoreChartData: {},
  },
  mutations: {
    SET_COIN_DETAILS: (
      state,
      { coinName, currency_code, loading, scoreChartData, locale, conv_rate }
    ) => {
      state.loading = loading
      state.coinName = coinName
      state.currency.currency_code = currency_code
      state.currency.locale = locale
      state.currency.conv_rate = conv_rate
      state.scoreChartData = scoreChartData
    },
  },
  actions: {
    FETCH_COIN_DETAILS: async ({ commit }, { coinName, currencyCode }) => {
      try {
        const { data } = await Axios.get(
          `/api/v1.0/widgets/trend?qc_key=${coinName}&currency=${currencyCode}`
        )

        const scoreData = {
          priceUsd: data.data.price_usd,
          technical_score: data.data.technical_score,
          symbol_name: data.data.symbol_name,
          qc_key: data.data.qc_key,
          price24h: data.data.price24h,
        }

        const locale = data.currency.locale
        const conv_rate = data.currency.conv_rate

        commit('SET_COIN_DETAILS', {
          loading: false,
          coinName: coinName,
          currency_code: currencyCode,
          locale: locale,
          conv_rate: conv_rate,
          scoreChartData: scoreData,
        })

        return scoreData
      } catch (error) {
        throw new Error(error)
      }
    },
  },
  modules: {},
})
